import { HYDRATE } from "next-redux-wrapper"
import _isEmpty from "lodash/isEmpty"
import { aemAxiosInstance } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import CONS, {
  GET_PROFILE_ENDPOINT,
  GLOBAL_CONFIGURATION_ENDPOINT,
  SEARCH_PLP_ENDPOINT,
  ZIP_FILE_DOWNLOAD_ENDPOINT,
  EDIT_PROFILE_ENDPOINT,
  addAddressEndPoint,
  DELETE_ACCOUNT_ENDPOINT,
  CHANGE_ACCOUNT_PASSWORD_ENDPOINT,
  INSTALLATION_SERVICES_PATH,
  checkDuplicacyUrl,
  resetFactorUrl,
  enrollFactorUrl,
  updateNumberUrl,
  checkDuplicacyUrlForUpdateNumber,
  sendTriggerURL,
  editAddressEndPoint,
} from "@/constants/index"
import {
  getShortenedUrl,
  mapStateToCountryHandler,
  arrayMove,
  getPdpUrlParams,
} from "@/utils/helper"
import { apim } from "@/constants/api"
import { addToCart } from "@/store/features/cartSlice"
import { store } from "@/store"

const initialState = {
  datalayerPageName: "",
  eventPageType: "",
  accountTypeListPath: "",
  businessListPath: "",
  countryListPath: "",
  commonListPath: "",
  trackingListPath: "",
  stateListPath: "",
  cardTypesPath: "",
  dealerDetailsPath: "",
  storeCountryListPath: "",
  placeOfPurchaseListPath: "",
  accountTypeList: [],
  businessList: [],
  legalPageLinks: {},
  countryList: [],
  stateList: [],
  stateToCountryList: [],
  placeOfPurchaseList: [],
  cardType: "",
  dealerDetails: [],
  userMenuFragment: "",
  guestMenuFragment: "",
  status: "",
  error: "",
  subscribeModal: {
    show: false,
    modalContent: {},
    active: 0,
  },
  siteWideWarning: {
    isRendered: false,
  },
  newslettersignup: {},
  compareproducts: {},
  profile: "",
  brandLogoImg: "",
  globalScripts: [],
  dataLayerObj: "",
  analyticsScript: "",
  globalStyles: [],
  toaster: {
    message: "",
    isVisible: false,
    content: {},
  },
  showCartExpandedView: false,
  menu: {
    isOpen: false,
    mainCategoryActive: "",
    isRegionOpen: false,
    isAuthNavOpen: false,
  },
  pageIsInteractive: false,
  previewCart: { showPreview: false },
  promoBannerModal: {
    show: false,
    modalContent: { banner: {}, item: {}, count: 0 },
  },
  enableSiteWideWarning: false,
  FindingModelModal: {
    show: false,
    modalContent: {},
  },
  associateMembershipModal: {
    show: false,
    isRetired: false,
    modalContent: {},
  },
  showHalfCompare: false,
  showFullCompare: false,
  isProductInfo: false,
  consolidatedZipCodes: [],
  upsellInstallationServiceAuthData: {},
  isShowAppointmentModal: false,
  bpNumber: null,
  reasonListPath: "",
  reasonList: [],
  planTimingList: [],
  planTimingListPath: "",
  planTypeList: [],
  planTypeListPath: "",
}

export const getGenericListPath = createAsyncThunk(
  "generic/getGenericListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const accountTypeListPath = state?.generic?.accountTypeListPath ?? ""
    const businessListPath = state?.generic?.businessListPath ?? ""
    const countryListPath = state?.generic?.countryListPath ?? ""
    const commonListPath = state?.generic?.commonListPath ?? ""
    const trackingListPath = state?.generic?.trackingListPath ?? ""
    const stateListPath = state?.generic?.stateListPath ?? ""
    const cardTypesPath = state?.generic?.cardTypesPath ?? ""
    const dealerDetailsPath = state?.generic?.dealerDetailsPath ?? ""
    const planTimingListPath = state?.generic?.planTimingListPath ?? ""
    const planTypeListPath = state?.general?.planTypeListPath ?? ""
    const placeOfPurchaseListPath =
      state?.generic?.placeOfPurchaseListPath ?? ""
    const storeCountryListPath = state?.generic?.placeOfPurchaseListPath ?? ""
    const reasonListPath = state?.generic?.reasonListPath ?? ""
    let data = {}

    if (
      accountTypeListPath ||
      businessListPath ||
      countryListPath ||
      commonListPath ||
      trackingListPath ||
      stateListPath ||
      cardTypesPath ||
      placeOfPurchaseListPath ||
      reasonListPath ||
      dealerDetailsPath ||
      planTimingListPath ||
      planTypeListPath ||
      (storeCountryListPath && !reload)
    ) {
      data = {
        accountTypeList: accountTypeListPath,
        businessList: businessListPath,
        countryList: countryListPath,
        commonList: commonListPath,
        trackingList: trackingListPath,
        stateList: stateListPath,
        cardTypes: cardTypesPath,
        placeOfPurchaseList: placeOfPurchaseListPath,
        storeCountryListPath: storeCountryListPath,
        reasonList: reasonListPath,
        dealerDetails: dealerDetailsPath,
        planTimingList: planTimingListPath,
        planTypeList: planTypeListPath,
      }
      return data
    }

    const { general } = await getConfig()
    if (general?.genericListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + general?.genericListPath
      )
      return response.data
    }
    return data
  }
)
export const getCardType = createAsyncThunk(
  "generic/cardTypesPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const cardTypesPath = state?.generic?.cardTypesPath
    if (cardTypesPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + cardTypesPath
      )
      return response.data
    }
    return {}
  }
)

export const getDealerDetails = createAsyncThunk(
  "generic/dealerDetailsPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const dealerDetailsPath = state?.generic?.dealerDetailsPath
    if (dealerDetailsPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + dealerDetailsPath
      )
      return response.data
    }
    return []
  }
)

export const getTrackingList = createAsyncThunk(
  "generic/trackingListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const trackingListPath = state?.generic?.trackingListPath
    if (trackingListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + trackingListPath
      )
      return response.data
    }
    return {}
  }
)

export const getBusinessList = createAsyncThunk(
  "generic/getBusinessList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const businessList = state?.generic?.businessList
    if (businessList?.length > 0 && !reload) return businessList
    const businessListPath = state?.generic?.businessListPath
    if (businessListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + businessListPath
      )
      return response.data
    }
    return []
  }
)

export const getAccountBusinessList = createAsyncThunk(
  "generic/getAccountBusinessList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const accountTypeList = state?.generic?.accountTypeList
    if (accountTypeList?.length > 0 && !reload) return accountTypeList
    const accountTypeListPath = state?.generic?.accountTypeListPath
    if (accountTypeListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + accountTypeListPath
      )
      return response.data
    }
    return []
  }
)

export const getLegalPageLinks = createAsyncThunk(
  "generic/getLegalPageLinks",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const legalPageLinks = state?.generic?.legalPageLinks
    if (!_isEmpty(legalPageLinks) > 0 && !reload) return legalPageLinks
    const { general } = await getConfig()
    if (general?.legalPageListPath) {
      const response = await aemAxiosInstance.get(general?.legalPageListPath)
      return response.data
    }
    return {}
  }
)

export const getAuthMenuFragment = createAsyncThunk(
  "generic/getAuthMenuFragment",
  async ({ reload = false, path, fragmentName } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const fragment = state?.generic[fragmentName] ?? ""
    const payload = {}
    if (fragment && !reload) {
      payload[fragmentName] = fragment
      return payload
    }
    if (path) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + path
      )
      payload[fragmentName] = response.data.commonTopics
        ? await getShortenedUrl(response.data.commonTopics)
        : ""
      return payload
    }
    return {}
  }
)

export const getProfile = createAsyncThunk("getprofile", async () => {
  const response = await apim.get(`${GET_PROFILE_ENDPOINT}`)
  return response.data
})

export const getCountryList = createAsyncThunk(
  "generic/countryListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const countryList = state?.generic?.countryList
    if (countryList?.length > 0 && !reload) return countryList
    const countryListPath = state?.generic?.countryListPath
    if (countryListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + countryListPath
      )
      return response.data
    }
    return {}
  }
)
export const getstateList = createAsyncThunk(
  "generic/stateListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const stateList = state?.generic?.stateList
    if (stateList?.length > 0 && !reload) return stateList
    const stateListPath = state?.generic?.stateListPath
    if (stateListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + stateListPath
      )
      return response.data
    }
    return {}
  }
)

export const getStoreCountriesList = createAsyncThunk(
  "generic/storeCountryListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const storeCountryList = state?.generic?.storeCountryList
    if (storeCountryList?.length > 0 && !reload) return storeCountryList
    const storeCountryListPath = state?.generic?.storeCountryListPath
    if (storeCountryListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + storeCountryListPath
      )
      return response.data
    }
    return {}
  }
)

export const getplaceOfPurchaseList = createAsyncThunk(
  "generic/placeOfPurchaseList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const placeOfPurchaseList = state?.generic?.placeOfPurchaseList
    if (placeOfPurchaseList?.length > 0 && !reload) return placeOfPurchaseList
    const placeOfPurchaseListPath = state?.generic?.placeOfPurchaseListPath
    if (placeOfPurchaseListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + placeOfPurchaseListPath
      )
      return response.data?.map(purchase => purchase.value)
    }
    return {}
  }
)

export const getplanTimingList = createAsyncThunk(
  "generic/planTimingList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const planTimingList = state?.generic?.planTimingList
    if (planTimingList?.length > 0 && !reload) return planTimingList
    const planTimingListPath = state?.generic?.planTimingListPath
    if (planTimingListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + planTimingListPath
      )
      return response.data
    }
    return []
  }
)

export const getplanTypeList = createAsyncThunk(
  "generic/planTypeList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const planTypeList = state?.generic?.planTypeList
    if (planTypeList?.length > 0 && !reload) return planTypeList
    const planTypeListPath = state?.generic?.planTypeListPath
    if (planTypeListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + planTypeListPath
      )
      return response.data
    }
    return []
  }
)

export const zipDownload = createAsyncThunk("zipDownload", async payload => {
  const response = await apim.post(ZIP_FILE_DOWNLOAD_ENDPOINT, payload, {
    responseType: "blob",
  })
  return response
})

export const searchPlp = createAsyncThunk("search-plp", async data => {
  try {
    const actions = data.actions
    const response = await apim.get(SEARCH_PLP_ENDPOINT, {
      params: {
        q: "*:*",
        fl: "CustomerFacingBrand_s, RegionOnBackOrder_s",
        fq: `sku_s:(${actions[0]?.sku})`,
        collections: "service_parts",
        profilename: "profile_service_parts_Search",
      },
    })
    if (response?.data?.response?.docs[0]) {
      const newActions = []
      const newData = {}
      actions.forEach(action => {
        action["backorder"] =
          response.data.response.docs[0]["RegionOnBackOrder_s"] === "Yes"
        action["brand"] =
          response.data.response.docs[0]["CustomerFacingBrand_s"]
        newActions.push(action)
      })
      newData["actions"] = newActions
      return store.dispatch(addToCart(newData))
    }
  } catch (e) {
    return store.dispatch(addToCart(data))
  }
})

export const getUserProfile = createAsyncThunk("getuserprofile", async () => {
  let profile = {}
  try {
    const res = await apim.get(`${GET_PROFILE_ENDPOINT}`)
    if (res && res.data) {
      localStorage.setItem("profile-version", res.data.version)
      profile = res.data
      profile.postalCode = profile.addresses[0]?.postalCode
      const profileAddr =
        profile.addresses?.find(
          addr => addr.custom?.fields?.label === "Profile"
        ) ?? {}
      profile.profileAddrId = profileAddr.id
      profile.addresses = profile.addresses?.filter(
        addr => addr.custom?.fields?.label !== "Profile"
      )
      let addresses = profile.addresses
      if (profile.defaultShippingAddressId) {
        const index = addresses.findIndex(
          item => item.id === profile.defaultShippingAddressId
        )
        addresses = arrayMove(profile.addresses, index, 0)
      }
      profile.addresses = addresses
    }
  } catch (err) {
    console.log("Failed to get user profile", err)
  }
  return profile
})
export const editProfile = createAsyncThunk(
  "editProfile",
  async (data, thunkAPI) => {
    let profile = []
    let actions = []
    const oktaTokenStorage = JSON.parse(
      localStorage.getItem("okta-token-storage")
    )
    const userType =
      oktaTokenStorage.accessToken.claims.persona[0].split(":")[1]
    if (userType === "TVS") {
      actions = [
        {
          action: "setFirstName",
          firstName: data.firstName,
        },
        {
          action: "setLastName",
          lastName: data.lastName,
        },
        {
          action: "setCompanyName",
          companyName: data.businessName,
        },
        {
          action: "setCustomField",
          name: "businessType",
          value: data.businessType,
        },
        {
          action: "setCustomField",
          name: "businessSubType",
          value: data.businessSubType,
        },
        {
          action: "changeAddress",
          addressId: data.addressId,
          address: {
            postalCode: data.businessZipCode,
            country: "US",
            custom: {
              type: {
                key: "custom-address",
              },
              fields: {
                label: "Profile",
              },
            },
          },
        },
      ]
    } else {
      actions = [
        {
          action: "setFirstName",
          firstName: data.firstName,
        },
        {
          action: "setLastName",
          lastName: data.lastName,
        },
      ]
    }
    const version = parseInt(localStorage.getItem("profile-version")) ?? 1
    try {
      const res = await apim.post(`${EDIT_PROFILE_ENDPOINT}`, {
        version: version,
        actions: actions,
      })
      if (res) {
        profile = await thunkAPI.dispatch(getUserProfile())

        localStorage.setItem(CONS?.KF_FORCE_RENEW_TOKEN, true)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Failed to edit profile", err)
    }
    const { payload: response } = profile
    return response
  }
)
export const checkSubscription = async user => {
  let response = {}
  try {
    const res = await apim.get(
      `clevertap/communication/preference?email=${user?.sub}`
    )
    response = res.data
  } catch (e) {
    console.log(e, "error")
  }
  return response
}
export const subscribeApi = async (user, isSubscribed) => {
  let res = {}
  try {
    const response = await apim.post(`clevertap/communication/preference`, {
      email: user?.sub,
      userId: user?.uid,
      isSubscribed: isSubscribed,
    })
    res = response?.data
  } catch (e) {
    console.log(e, "error")
  }
  return res
}
export const addAddress = createAsyncThunk("addAddress", async data => {
  const resPayload = {
    addresses: [],
    defaultShippingAddressId: "",
  }
  const version = parseInt(localStorage.getItem("profile-version")) ?? 1
  try {
    const payload = Array.isArray(data)
      ? {
          version: version,
          actions: data,
        }
      : {
          version: version,
          actions: [
            {
              action: "addAddress",
              address: data,
            },
          ],
        }
    const res = await apim.post(`${addAddressEndPoint}`, payload)
    if (res && res?.data) {
      localStorage.setItem("profile-version", res?.data?.version)
      if (res?.data?.addresses?.length > 0) {
        resPayload.addresses =
          res?.data?.addresses?.filter(
            addr => addr?.custom?.fields?.label !== "Profile"
          ) ?? []
        resPayload.defaultShippingAddressId =
          res?.data?.defaultShippingAddressId
      }
    }
  } catch (err) {
    console.log("Failed to add address", err)
    const { response: { data: { error = "" } } = {} } = err
    return { error }
  }
  return resPayload
})
export const editAddress = createAsyncThunk("editAddress", async payload => {
  const resPayload = {
    addresses: [],
    defaultShippingAddressId: "",
  }
  const version = parseInt(localStorage.getItem("profile-version")) ?? 1
  try {
    const res = await apim.post(`${addAddressEndPoint}`, {
      version: version,
      actions: [
        {
          action: "changeAddress",
          addressId: payload?.id,
          address: payload?.addr,
        },
      ],
    })
    if (res && res?.data) {
      localStorage.setItem("profile-version", res.data.version)
      if (res?.data?.addresses?.length > 0) {
        resPayload.addresses =
          res?.data?.addresses?.filter(
            addr => addr?.custom?.fields?.label !== "Profile"
          ) ?? []
        resPayload.defaultShippingAddressId =
          res?.data?.defaultShippingAddressId
      }
    }
  } catch (err) {
    console.log("Failed to edit address", err)
  }
  return resPayload
})
export const setDefaultAddress = createAsyncThunk(
  "setDefaultAddress",
  async addressId => {
    const resPayload = {
      addresses: [],
      defaultShippingAddressId: "",
    }
    const version = parseInt(localStorage.getItem("profile-version")) ?? 1
    try {
      const res = await apim.post(`${addAddressEndPoint}`, {
        version: version,
        actions: [
          {
            action: "setDefaultShippingAddress",
            addressId: addressId,
          },
        ],
      })
      if (res && res?.data) {
        localStorage.setItem("profile-version", res?.data?.version)
        if (res?.data?.addresses?.length > 0) {
          resPayload.addresses =
            res?.data?.addresses?.filter(
              addr => addr?.custom?.fields?.label !== "Profile"
            ) ?? []
          resPayload.defaultShippingAddressId =
            res?.data?.defaultShippingAddressId
        }
      }
    } catch (err) {
      console.log("Failed to set default", err)
    }
    return resPayload
  }
)
export const removeAddress = createAsyncThunk(
  "removeAddress",
  async addressId => {
    const resPayload = {
      addresses: [],
      defaultShippingAddressId: "",
    }
    const version = parseInt(localStorage.getItem("profile-version")) ?? 1
    try {
      const res = await apim.post(`${addAddressEndPoint}`, {
        version: version,
        actions: [
          {
            action: "removeAddress",
            addressId: addressId,
          },
        ],
      })
      if (res && res?.data) {
        console.log(res, "res")
        console.log("enter remove address")
        localStorage.setItem("profile-version", res.data?.version)
        if (res.data?.addresses.length > 0) {
          resPayload.addresses =
            res.data?.addresses?.filter(
              addr => addr?.custom?.fields?.label !== "Profile"
            ) ?? []
          resPayload.defaultShippingAddressId =
            res?.data?.defaultShippingAddressId
        }
      }
    } catch (err) {
      console.log("Failed to remove address", err)
    }
    return resPayload
  }
)

export const deleteAccount = createAsyncThunk("deleteAccount", async () => {
  try {
    const res = await apim.delete(`${DELETE_ACCOUNT_ENDPOINT}`)
    if (res && res.data) return true
  } catch (err) {
    console.log("Failed to delete account", err)
  }
})

export const resetPassword = createAsyncThunk("resetPassword", async data => {
  try {
    return await apim.post(`${CHANGE_ACCOUNT_PASSWORD_ENDPOINT}`, data)
  } catch (err) {
    console.log("Failed to reset password", err)
    return err.response
  }
})

export const uploadImage = createAsyncThunk("", async file => {
  const fileName = file?.uplaodData?.name?.toLowerCase()?.replace(/ /g, "-")
  const res = await apim.put(
    `/crm/asset/upload?fileName=${fileName}${
      file?.isReturn ? "&formAsset=formupload&brand=KIN" : ""
    }`,
    file?.uplaodData,
    {
      headers: { "Content-Type": file?.uplaodData?.type },
    }
  )

  if (res && res.data) return res.data
})

export const getCommonList = createAsyncThunk(
  "generic/getCommonList",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const commonList = state?.generic?.commonList
    if (commonList?.length > 0 && !reload) return commonList
    const commonListPath = state?.generic?.commonListPath
    if (commonListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + commonListPath
      )
      return response.data
    }
    return []
  }
)

export const getReasonList = createAsyncThunk(
  "generic/reasonListPath",
  async ({ reload = false } = {}, thunkAPI) => {
    const state = thunkAPI.getState()
    const reasonListPath = state?.generic?.reasonListPath
    if (reasonListPath) {
      const response = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + reasonListPath
      )
      return response.data
    }
    return []
  }
)

export const closeCompareProductView = (url = "") => {
  store.dispatch(setShowHalfCompare(false))
  store.dispatch(setShowFullCompare(true))

  const query = getPdpUrlParams()
  if (window?.location?.pathname === INSTALLATION_SERVICES_PATH) {
    const newUrl = window.location.hash
      ? `${window.location.pathname}${window.location.hash}`
      : window.location.pathname
    window.history.replaceState({ previousUrl: url }, document.title, newUrl)
  } else if (query) {
    window.history.replaceState(window.location.pathname, document.title, query)
  } else {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  document.body.classList.remove("compare-open", "scroll-lock", "modal-open")
}

export const checkDuplicacy = createAsyncThunk(
  "customers/users",
  async mobileNumber => {
    try {
      const payload = {
        login: mobileNumber,
      }
      const { data: content } = await apim.post(`${checkDuplicacyUrl}`, payload)
      return content
    } catch (error) {
      return error?.response?.data
    }
  }
)

export const checkIsUserExist = createAsyncThunk(
  "customers/users",
  async mobileNumber => {
    try {
      const { data: content } = await apim.get(
        `${checkDuplicacyUrlForUpdateNumber}/${mobileNumber}`
      )
      return content
    } catch (error) {
      return error?.response?.data
    }
  }
)
export const resetFactor = createAsyncThunk(
  "customer/reset/factor",
  async userid => {
    const payload = { userId: userid }
    const response = await apim.post(`${resetFactorUrl}`, payload)
    return response
  }
)

export const enrollFactor = createAsyncThunk(
  "customer/reset/factor",
  async ({ userid, data }) => {
    const payload = { userId: userid, data }
    try {
      const response = await apim.post(`${enrollFactorUrl}`, payload)
      return response
    } catch (error) {
      return error
    }
  }
)
export const updateNumber = createAsyncThunk(
  "update/number",
  async ({ actions }) => {
    const version = parseInt(localStorage.getItem("profile-version")) ?? 1
    const payload = { version, actions }
    const response = await apim.post(`${updateNumberUrl}`, payload)
    return response
  }
)

export const sendTrigger = createAsyncThunk(
  "send/trigger",
  async ({ firstName, lastName, emailId, phoneNumber }) => {
    const payload = {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      geolocation: "India",
    }
    const url = `${sendTriggerURL}?evtName=logged-in`
    try {
      const res = await apim.post(url, payload)
      return res
    } catch (error) {
      return error
    }
  }
)

export const unsetDefaultAddress = createAsyncThunk(
  "customer/default/address",
  async payload => {
    const resPayload = {
      addresses: [],
      defaultShippingAddressId: "",
    }
    const body = {
      version: payload.profileVersion,
      actions: [
        {
          action: "removeShippingAddressId",
          addressId: payload.id,
        },
      ],
    }
    const res = await apim.post(`${editAddressEndPoint}`, body)
    if (res && res.data) {
      resPayload.version = res.data.version
      if (res.data.addresses.length > 0) {
        resPayload.addresses =
          res.data.addresses?.filter(
            addr => addr.custom?.fields?.label !== "Profile"
          ) ?? []
        resPayload.defaultShippingAddressId = res.data.defaultShippingAddressId
      }
    }
    return resPayload
  }
)

export const genericSlice = createSlice({
  name: "generic",
  initialState,
  reducers: {
    setSubscribeModalContent(state, action) {
      state.subscribeModal = {
        ...state.subscribeModal,
        modalContent: action.payload,
      }
    },
    setCompareproductsStaticText(state, action) {
      state.compareproducts = {
        ...state.compareproducts,
        content: action.payload,
      }
    },

    setSitewideWarningContent(state, action) {
      state.siteWideWarning = {
        ...state.subscribeModal,
        content: action.payload,
      }
    },

    setSitewideNewsLetterContent(state, action) {
      state.newslettersignup = {
        ...state.newslettersignup,
        content: action.payload,
      }
    },

    setSitewideWarningRendered(state, action) {
      state.siteWideWarning = {
        ...state.subscribeModal,
        isRendered: action.payload,
      }
    },
    setBrandLogoImg(state, action) {
      state.brandLogoImg = action.payload
    },

    setGlobalScripts(state, action) {
      state.globalScripts = state.globalScripts.concat(action.payload)
    },
    setDataLayerObj(state, action) {
      state.dataLayerObj = action.payload
    },

    setGlobalStyles(state, action) {
      state.globalStyles = state.globalStyles.concat(action.payload)
    },

    setAnalyticsScript(state, action) {
      state.analyticsScript = action.payload
    },

    showCartExpandedView(state, action) {
      state.showCartExpandedView = true
    },
    hideCartExpandedView(state, action) {
      state.showCartExpandedView = false
    },
    showToast(state, action) {
      state.toaster = {
        ...state.toaster,
        ...action.payload,
      }
    },
    hideToast(state, action) {
      state.toaster = {
        ...state.toaster,
        message: "",
        isVisible: false,
      }
    },
    setToastContent(state, action) {
      state.toaster = {
        ...state.toaster,
        content: action.payload,
      }
    },
    openMenu(state, action) {
      state.menu = {
        ...state.menu,
        isOpen: true,
      }
    },
    closeMenu(state, action) {
      state.menu = {
        ...state.menu,
        isOpen: false,
      }
    },
    setActiveMainCategory(state, action) {
      state.menu = {
        ...state.menu,
        mainCategoryActive: action.payload.item,
      }
    },
    setRegionNav(state, action) {
      state.menu = {
        ...state.menu,
        isRegionOpen: action.payload,
      }
    },
    setAuthNav(state, action) {
      state.menu = {
        ...state.menu,
        isAuthNavOpen: action.payload,
      }
    },
    setPageIsIntercative(state, action) {
      state.pageIsInteractive = action.payload
    },
    setPageIsIntercative(state, action) {
      state.pageIsInteractive = action.payload
    },

    showPreviewCart(state, action) {
      window.previewCartKeySource = false
      state.previewCart = {
        ...state.previewCart,
        showPreview: true,
      }
    },
    hidePreviewCart(state, action) {
      window.previewCartKeySource = true
      state.previewCart = {
        ...state.previewCart,
        showPreview: false,
      }
    },

    setDatalayerPageName(state, action) {
      let trimmed = action.payload
      // will remove .model.json from the incoming string
      trimmed = trimmed ? trimmed.replace(".model.json", "") : trimmed
      state.datalayerPageName = trimmed
    },
    setGbhDataLayerCustom(state, action) {
      const { datalayerPageName, eventPageType, pageType } = action.payload
      if (datalayerPageName) {
        state.datalayerPageName = datalayerPageName
      } else {
        state.datalayerPageName = ""
      }
      if (eventPageType) {
        state.eventPageType = window.eventPageType = eventPageType
      } else {
        state.eventPageType = ""
      }
      if (pageType) {
        state.pageType = window.pageType = pageType
      } else {
        state.pageType = ""
      }
    },
    showPromoModal(state, action) {
      state.promoBannerModal = {
        ...action.payload,
      }
    },
    mapStateToCountry: (state, action) => {
      const { countryList, stateList } = action.payload
      const stateToCountryList = mapStateToCountryHandler(
        countryList,
        stateList
      )
      state.stateToCountryList = stateToCountryList
    },
    toggleSiteWideWarning: (state, action) => {
      state.enableSiteWideWarning = !state.enableSiteWideWarning
    },
    showFindingModelPopup(state, action) {
      state.FindingModelModal = {
        show: true,
        modalContent: {},
      }
    },
    hideFindingModelPopup(state, action) {
      state.FindingModelModal = {
        show: false,
        modalContent: {},
      }
    },
    setShowHalfCompare(state, action) {
      state.showHalfCompare = action.payload
    },

    setShowFullCompare(state, action) {
      state.showFullCompare = action.payload
    },
    setPageObject(state, action) {
      state.isProductInfo = action.payload
    },
    setConsolidatedZipCodes(state, action) {
      state.consolidatedZipCodes = action.payload
    },
    setUpsellInstallationServiceData(state, action) {
      state.upsellInstallationServiceAuthData = action.payload
    },
    showAppointmentModal: (state, action) => {
      state.isShowAppointmentModal = true
      state.bpNumber = action.payload
    },
    hideAppointmentModal: state => {
      state.isShowAppointmentModal = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.generic,
        }
      })
      .addCase(getGenericListPath.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getGenericListPath.fulfilled, (state, action) => {
        state.status = "succeeded"
        const data = action.payload
        for (const key of Object.keys(data)) {
          state[key + "Path"] = data[key]
        }
      })
      .addCase(getGenericListPath.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getBusinessList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getBusinessList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.businessList = action.payload
      })
      .addCase(getBusinessList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getAccountBusinessList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getAccountBusinessList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.accountTypeList = action.payload
      })
      .addCase(getAccountBusinessList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getLegalPageLinks.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getLegalPageLinks.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.legalPageLinks = action.payload
      })
      .addCase(getLegalPageLinks.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getAuthMenuFragment.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getAuthMenuFragment.fulfilled, (state, action) => {
        state.status = "succeeded"
        for (const key in action.payload) {
          if ({}.hasOwnProperty.call(action.payload, key)) {
            state[key] = action.payload[key]
          }
        }
      })
      .addCase(getAuthMenuFragment.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getCountryList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.countryList = action.payload
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getstateList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getstateList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.stateList = action.payload
      })
      .addCase(getStoreCountriesList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getStoreCountriesList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getStoreCountriesList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.stateList = action.payload
      })
      .addCase(getstateList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getplaceOfPurchaseList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getplaceOfPurchaseList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.placeOfPurchaseList = action.payload
      })
      .addCase(getplaceOfPurchaseList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getCardType.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getCardType.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.cardType = action.payload
      })
      .addCase(getCardType.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getDealerDetails.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getDealerDetails.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.dealerDetails = action.payload
      })
      .addCase(getDealerDetails.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getReasonList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getReasonList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.reasonList = action.payload
      })
      .addCase(getReasonList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getplanTimingList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getplanTimingList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.planTimingList = action.payload
      })
      .addCase(getplanTimingList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

      .addCase(getplanTypeList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getplanTypeList.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.planTypeList = action.payload
      })
      .addCase(getplanTypeList.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setCompareproductsStaticText,
  setSubscribeModalContent,
  setSitewideWarningContent,
  setSitewideWarningRendered,
  setBrandLogoImg,
  setGlobalScripts,
  setGlobalStyles,
  setDataLayerObj,
  setAnalyticsScripts,
  setCompareExpiry,
  showToast,
  clearAllCompareProducts,
  showPreviewCart,
  hidePreviewCart,
  showCartExpandedView,
  hideCartExpandedView,
  hideToast,
  openMenu,
  closeMenu,
  setActiveMainCategory,
  setRegionNav,
  setAuthNav,
  setAnalyticsScript,
  setPageIsIntercative,
  setDatalayerPageName,
  setGbhDataLayerCustom,
  setSitewideNewsLetterContent,
  setToastContent,
  showPromoModal,
  mapStateToCountry,
  toggleSiteWideWarning,
  showFindingModelPopup,
  hideFindingModelPopup,
  setShowHalfCompare,
  setShowFullCompare,
  setPageObject,
  setConsolidatedZipCodes,
  setUpsellInstallationServiceData,
  showAppointmentModal,
  hideAppointmentModal,
} = genericSlice.actions
export const selectGenericState = state => state.generic
export default genericSlice.reducer
